import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';

const getCmsPremiStart = () => {
  return {
    type: actionTypes.GET_CMS_PREMI_START,
  };
};

const getCmsPremiSuccess = content => {
  return {
    type: actionTypes.GET_CMS_PREMI_SUCCESS,
    content,
  };
};

const getCmsPremiError = error => {
  return {
    type: actionTypes.GET_CMS_PREMI_ERROR,
    error,
  };
};

export const getCmsPremi = () => {
  let endpoint = `cms/premi`;
  return dispatch => {
    dispatch(getCmsPremiStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsPremiSuccess(result.data));
          resolve();
        })
        .catch(error => {
          dispatch(getCmsPremiError(error));
          reject(error);
        });
    });
  };
};

const getCmsHeaderSuccess = content => {
  return {
    type: actionTypes.GET_CMS_HEADER_SUCCESS,
    content,
  };
};

const getCmsFooterStart = () => {
  return {
    type: actionTypes.GET_CMS_FOOTER_START,
  };
};

const getCmsFooterSuccess = content => {
  return {
    type: actionTypes.GET_CMS_FOOTER_SUCCESS,
    content,
  };
};

const getCmsFooterError = error => {
  return {
    type: actionTypes.GET_CMS_FOOTER_ERROR,
    error,
  };
};

export const getCmsHeader = () => {
  let endpoint = `cms/header`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsHeaderSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const getCmsFooter = () => {
  let endpoint = `cms/footer`;
  return dispatch => {
    dispatch(getCmsFooterStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsFooterSuccess(result.data));
          resolve();
        })
        .catch(error => {
          dispatch(getCmsFooterError(error));
          reject(error);
        });
    });
  };
};

export const getCmsAuthentication = () => {
  let endpoint = `cms/authentication`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsAuthenticationSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsAuthenticationSuccess = content => {
  return {
    type: actionTypes.GET_CMS_AUTHENTICATION_SUCCESS,
    content,
  };
};

export const getCmsProfile = () => {
  let endpoint = `cms/profile`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsProfileSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsProfileSuccess = content => {
  return {
    type: actionTypes.GET_CMS_PROFILE_SUCCESS,
    content,
  };
};

export const getCmsContact = () => {
  let endpoint = `cms/contact`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsContactSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsContactSuccess = content => {
  return {
    type: actionTypes.GET_CMS_CONTACT_SUCCESS,
    content,
  };
};

export const getCmsHowItWorks = () => {
  let endpoint = `cms/how_it_works`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsHowItWorksSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsHowItWorksSuccess = content => {
  return {
    type: actionTypes.GET_CMS_HOW_IT_WORKS_SUCCESS,
    content,
  };
};
const getCmsOcrSuccess = content => {
  return {
    type: actionTypes.GET_CMS_OCR_SUCCESS,
    content,
  };
};

export const getCmsHome = () => {
  let endpoint = `cms/home`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsHomeSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const getCmsOcr = () => {
  let endpoint = `cms/ocr`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsOcrSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const getCmsMeccanica = () => {
  let endpoint = `cms/meccanica`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsMeccanicaSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};
export const getCmsCatalog = () => {
  let endpoint = `cms/catalog_secondary`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsCatalogSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsHomeSuccess = content => {
  return {
    type: actionTypes.GET_CMS_HOME_SUCCESS,
    content,
  };
};
const getCmsMeccanicaSuccess = content => {
  return {
    type: actionTypes.GET_CMS_MECCANICA_SUCCESS,
    content,
  };
};
const getCmsCatalogSuccess = content => {
  return {
    type: actionTypes.GET_CMS_CATALOG_SUCCESS,
    content,
  };
};

export const getCmsGame = () => {
  let endpoint = `cms/game`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsGameSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsGameSuccess = content => {
  return {
    type: actionTypes.GET_CMS_GAME_SUCCESS,
    content,
  };
};

export const getCmsAdvantages = () => {
  let endpoint = `cms/advantages`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsAdvantagesSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsAdvantagesSuccess = content => {
  return {
    type: actionTypes.GET_CMS_ADVANTAGES_SUCCESS,
    content,
  };
};

export const getCmsTutorial = () => {
  let endpoint = `cms/tutorial`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsTutorialSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsTutorialSuccess = content => {
  return {
    type: actionTypes.GET_CMS_TOTURIAL_SUCCESS,
    content,
  };
};

export const getCmsHowToParticipate = () => {
  let endpoint = `cms/come-partecipare`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsHowToParticipateSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsHowToParticipateSuccess = content => {
  return {
    type: actionTypes.GET_CMS_HOW_TO_PARTICIPATE_SUCCESS,
    content,
  };
};
export const getCmsMissions = () => {
  let endpoint = `cms/mission`;
  return dispatch => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(getCmsMissionsSuccess(result.data));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

const getCmsMissionsSuccess = content => {
  return {
    type: actionTypes.GET_CMS_MISSIONS_SUCCESS,
    content,
  };
};
