import React from 'react';
import { useSelector } from 'react-redux';
import Notification from '../HomeBlocks/Notification';

const HeaderCMSContents = () => {
  const headerContents = useSelector(state => state.cms.header[0]?.contents || []);

  return headerContents.map((item, index) => (
    <Notification
      key={item.id}
      data={headerContents}
      notif={item}
      bg={item.img}
      width={item.width}
    />
  ));
};

export default HeaderCMSContents;
