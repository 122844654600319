import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindowF,
  Autocomplete,
} from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import { Box, Col, Flex, Text, Wrapper } from '../../components/Ui';
import ReactSelect from 'react-select';
import styled, { useTheme } from 'styled-components/macro';
import ReactShadowScroll from 'react-shadow-scroll';
import { padding } from 'styled-system';
//import { BiSolidNavigation } from 'react-icons/io';

const AddressWrapper = styled.div`
  input {
    width: 100%;
    color: ${props => props.theme.colore_testo_campi};
    padding: 0 15px;
    background-color: ${props => props.theme.input.bg};
    border-radius: 4px;
    flex-grow: 1;
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    outline: none;
    opacity: ${props => (props.readonly ? '0.3' : '1')};
    border: 2px solid ${props => props.theme.input.bg};
    &::placeholder {
      color: #47545d59;
    }
  }
`;

const libraries = ['places'];
const Map = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBZqcJgdHRsDBSexX8uu0RGFMTcNOl0teY',
    libraries,
  });

  const [markers, setMarkers] = useState([]);
  const [search, setSearch] = useState('');
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 41.8719, lng: 12.5674 });
  const [activePlace, setActivePlace] = useState(null);
  const theme = useTheme();
  const places = useSelector(state => state.app.places);
  const [filteredPlaces, setFilteredPlaces] = useState(places);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [address, setAddress] = useState('');
  const [getCurrentPosition, setGetCurrentPosition] = useState(null);
  useEffect(() => {
    setFilteredPlaces(places);
  }, []);
  /*   useEffect(() => {
    function getGeolocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            console.log({ lat: position.coords.latitude, lng: position.coords.longitude });
            setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
            map.setZoom(10);
          },
          error => {
            console.error('Error getting location:', error);
          },
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }
    map && getGeolocation();
  }, [map]); */

  const handleSearch = activeFiltered => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: activeFiltered }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        map.panTo(location);
        map.setZoom(10);
        setCenter({ lat: location.lat(), lng: location.lng() });
        setMarkers(prev => [...prev, { lat: location.lat(), lng: location.lng() }]);
      } else {
        setCenter({ lat: 41.8719, lng: 12.5674 });
        map.setZoom(5);
      }
    });
  };

  if (loadError) return <div>Error loading Google Maps: {loadError.message}</div>;
  if (!isLoaded) return <div>Loading...</div>;
  //  console.log({ activePlace });

  const handleSelect = async selectedAddress => {
    setAddress(selectedAddress);
    handleSearch(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const provincia_slug = results[0]?.address_components.find(component =>
        component.types.includes('administrative_area_level_3'),
      )?.short_name;
      const regione = results[0]?.address_components.find(component =>
        component.types.includes('administrative_area_level_1'),
      )?.short_name;
      setFilteredPlaces(
        places.filter(
          place =>
            place.provincia_slug.toLowerCase() === provincia_slug.toLowerCase() &&
            place.regione_slug.toLowerCase() === regione.toLowerCase(),
        ),
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };
  console.log({ center });
  const handleGeolocalization = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log({ lat: position.coords.latitude, lng: position.coords.longitude });
          setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
          map.setZoom(10);
        },
        error => {
          console.error('Error getting location:', error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Wrapper style={{ marginBottom: '50px' }}>
      <Flex align='stretch'>
        <Col width={75} padding='0'>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '600px' }}
            center={center}
            zoom={5}
            onLoad={mapInstance => setMap(mapInstance)}
            onClick={() => setActivePlace(null)}
          >
            <Marker position={center} />
            {places.map((marker, index) => {
              return (
                <Marker
                  key={index}
                  position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
                  onClick={() => setActivePlace(marker)}
                  options={{
                    icon: {
                      url: require('../../assets/images/marker.png'),
                      scaledSize: new window.google.maps.Size(30, 40),
                    },
                    animation:
                      activePlace &&
                      activePlace.lat === marker.lat &&
                      activePlace.lng === marker.lng
                        ? window.google.maps.Animation.BOUNCE
                        : null,
                  }}
                />
              );
            })}

            {activePlace && (
              <InfoWindowF
                position={{ lat: Number(activePlace.lat), lng: Number(activePlace.lng) }}
                onCloseClick={() => setActivePlace(null)}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -50), // Adjust offset to move InfoWindow above the marker
                }}
              >
                <div>
                  <Text bold as='h3' className='mb-10'>
                    Ditributtore Info
                  </Text>
                  <Text size={14} className='mb-10'>
                    <strong>Latitude</strong>: {activePlace.lat}
                  </Text>
                  <Text size={14} className='mb-10'>
                    <strong>Longitude</strong>: {activePlace.lng}
                  </Text>
                  <Text size={14} className='mb-10'>
                    <strong>Store</strong>: {activePlace.store}
                  </Text>
                  {map.zoom !== 16 && (
                    <Text
                      onClick={() => {
                        map.panTo({ lat: +activePlace.lat, lng: +activePlace.lng });
                        map.setZoom(16);
                        setCenter({ lat: +activePlace.lat, lng: +activePlace.lng });
                      }}
                      size={14}
                      bold
                      className='mt-10'
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      Zoom in
                    </Text>
                  )}
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        </Col>

        <Col width={25} padding='0'>
          <Box fullheight padding='0'>
            {/*   <ReactSelectStyle
              className='basic-single'
              classNamePrefix='select'
              //isLoading={isLoading}
              isClearable={true}
              isSearchable={true}
              onChange={val => {
                setSearch(val ? val.value : '');
              }}
              value={search}
              name={'location'}
              options={places.map(place => ({
                label: place.regione,
                value: place.regione,
              }))}
              placeholder='Punto Vendita'
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  height: '50px',
                  borderRadius: '15px',
                  border: 'none',
                  backgroundColor: theme?.input?.bg,
                  borderRadius: theme?.border_radius_generale_campi,
                  fontSize: '14px',
                  fontWeight: '700',
                  padding: '0px 7px',
                }),
                dropdownIndicator: styles => ({
                  ...styles,
                  color: '#f00',
                }),
                placeholder: defaultStyles => {
                  return {
                    ...defaultStyles,
                    color: '#47545d59',
                    marginRight: '10px',
                  };
                },
              }}
            /> */}
            <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <AddressWrapper>
                  <div style={{ backgroundColor: '#19469B', padding: '20px' }}>
                    <input
                      {...getInputProps({
                        placeholder: 'Cerca per indirizzo...',
                        className: 'location-search-input',
                      })}
                    />
                    <Flex
                      gap='5px'
                      justify='right'
                      onClick={map && handleGeolocalization}
                      style={{ marginTop: '15px' }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='ionicon s-ion-icon'
                        viewBox='0 0 512 512'
                        width={14}
                        style={{ fill: '#fff' }}
                      >
                        <title>Navigate</title>
                        <path d='M272 464a16 16 0 01-16-16.42V264.13a8 8 0 00-8-8H64.41a16.31 16.31 0 01-15.49-10.65 16 16 0 018.41-19.87l384-176.15a16 16 0 0121.22 21.19l-176 384A16 16 0 01272 464z'></path>
                      </svg>
                      <Text bold color='#fff' style={{ cursor: 'pointer' }} size={14}>
                        Geolocalizzazione
                      </Text>
                    </Flex>
                  </div>
                  <div className='autocomplete-dropdown-container'>
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style: {
                              backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                              cursor: 'pointer',
                              padding: '10px',
                            },
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </AddressWrapper>
              )}
            </PlacesAutocomplete>
            <ReactShadowScroll style={{ height: '475px' }} shadow='none'>
              {filteredPlaces.length > 0 ? (
                <ul>
                  {filteredPlaces.map(place => (
                    <FilteredPlace active={selectedPlace?.store === place.store} key={place.store}>
                      <div>
                        <Text size={18} bold className='mb-10'>
                          {place.provincia_slug}
                        </Text>
                        <Text size={11} color={'#a8a8a8'}>
                          {place.store}
                        </Text>
                      </div>
                      <svg
                        fill='#000000'
                        width='30px'
                        height='30px'
                        viewBox='595 796 200 200'
                        enableBackground='new 595 796 200 200'
                        style={{ minWidth: '30px', minHeight: '30px', cursor: 'pointer' }}
                        onClick={() => {
                          setActivePlace(place);
                          setSelectedPlace(place);
                          window.scrollTo(0, 0);
                          handleSearch(place.regione);
                        }}
                      >
                        <g>
                          <path
                            d='M700.539,993.184c-1.29,1.77-3.349,2.816-5.539,2.816c-2.191,0-4.249-1.047-5.539-2.817l-55.964-76.807
		c-9.428-13.026-14.404-28.398-14.404-44.47C619.093,830.051,653.145,796,695,796s75.907,34.051,75.907,75.906
		c0,16.087-4.971,31.46-14.375,44.455L700.539,993.184z M695,808.734c-34.834,0-63.174,28.339-63.174,63.172
		c0,13.373,4.141,26.163,11.976,36.987l51.199,70.271l51.228-70.284c7.81-10.793,11.945-23.583,11.945-36.973
		C758.174,837.073,729.834,808.734,695,808.734z M695,906.293c-18.96,0-34.386-15.425-34.386-34.387
		c0-18.959,15.426-34.385,34.386-34.385c18.961,0,34.388,15.425,34.388,34.385C729.388,890.868,713.961,906.293,695,906.293z
		 M695,850.255c-11.939,0-21.652,9.712-21.652,21.651c0,11.94,9.713,21.654,21.652,21.654c11.94,0,21.654-9.714,21.654-21.654
		C716.654,859.967,706.94,850.255,695,850.255z'
                          />
                        </g>
                      </svg>
                    </FilteredPlace>
                  ))}
                </ul>
              ) : (
                <Text size={14} className='mt-20 mb-20' style={{ textAlign: 'center' }}>
                  Nessun risultato
                </Text>
              )}
            </ReactShadowScroll>
          </Box>
        </Col>
      </Flex>
    </Wrapper>
  );
};

const ReactSelectStyle = styled(ReactSelect)`
  width: 100%;
  border-radius: 15px;

  border: 1px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.transparent
        ? 'transparent'
        : 'transparent';
    }};

  .select__indicators {
    display: none;
  }

  .select__control {
    box-shadow: none;
    &:hover {
      border: none;
    }
  }
`;

const FilteredPlace = styled.li`
  padding: 20px 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export default Map;
