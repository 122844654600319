import React from 'react';
import styled from 'styled-components';

const Page404 = props => {
  const { message } = props;

  return (
    <Wrapper404>
      <h1>{message}</h1>
    </Wrapper404>
  );
};

const Wrapper404 = styled.div`
  display: flex;
  max-width: 750px;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #343a40;
  text-align: center;
`;

export default Page404;
