import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Modal, Text, Wrapper } from '../../components/Ui';
import CmsBlocks from '../../components/CmsBlocks';
import useForm from '../../hooks/useForm';
import { ocrForm } from '../../formsConfig/formsConfig';
import customAxiosNm from '../../config/axios-refresh-token';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const CustomWrapper = styled(Wrapper)`
  button {
    width: fit-content;
    margin: 0 auto;
  }
`;

function Ocr() {
  const ocr_datas = useSelector(state => state.cms.ocr_datas);
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const ocrDatsForm = ocrForm();
  const history = useHistory();
  // Function to set image state
  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
      setFormIsValid(false);
    }
  };

  // Function to set selected files state
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const submitHandler = () => {
    setIsLoading(true);
    var data = new FormData();

    let fileArray = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      }
    }
    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    customAxiosNm
      .post(`/ocr-receipt`, data)
      .then(response => {
        setIsLoading(false);

        if (response.status === 200) {
          setShowConfirmModal(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setShowConfirmModal(false);
      });
  };
  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,

    fileUploadHandler,
    deleteFileHandler,
    imgPreviewUrl,

    setFormValid,
    resetFormHandler,
    setFormIsValid,
    formIsValid,
    handleKeyDown,
    selectChange,
  } = useForm(submitHandler, ocrDatsForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.elementType === 'inputfile' ? immagine : inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={() => {
          deleteFileHandler();
          formData.file.valid = false;
        }}
        imagePreviewUrl={imgPreviewUrl}
        lang={'it'}
        setImage={setImage}
        setFormValid={setFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        resetImage={resetImage}
        onKeyDown={handleKeyDown}
        {...inp}
      />
    );
  });
  return (
    <>
      <CustomWrapper>
        <CmsBlocks cms={ocr_datas} />
        {inputs}
        {immagine && (
          <Button active onClick={submitHandler} disabled={isLoading} loading={isLoading}>
            Conferma
          </Button>
        )}
      </CustomWrapper>
      <Modal
        show={showConfirmModal}
        close={() => {
          setShowConfirmModal(false);
          setImmagine(false);
          setSelectedFiles([]);
          setImage(false);
          history.push('/');
        }}
      >
        <Text className='mb-20'>
          Il caricamento è andato a buon fine, entro 15 minuti i punti saranno accreditati
        </Text>
        {/*  <Button active>
          <Link to='/user/options/point'>Vedi punti</Link>
        </Button> */}
      </Modal>
    </>
  );
}

export default Ocr;
