import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TiPlus, TiMinus } from 'react-icons/ti';
import { Button, Flex, Text, Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';

import { AiOutlineQuestionCircle } from 'react-icons/ai';

import Input from '../../components/Ui/Input/InputField/InputField';

import { BiUserCheck, BiUserX } from 'react-icons/bi';
import { MdOutlineCheckCircle } from 'react-icons/md';
import { FiXCircle } from 'react-icons/fi';
import * as XLSX from 'xlsx';
import ExportToExcel from '../../components/ExportExcel';
const AccordionContext = React.createContext();

function findParent(target, data, parents = []) {
  for (const item of data) {
    const newParents = [...parents, item];

    if (
      item.codice_cliente?.trim().toLowerCase() === target.toLowerCase() ||
      item.ragione_sociale?.toLowerCase()?.trim().includes(target?.toLowerCase()?.trim())
    ) {
      return newParents;
    }
    if (item.children) {
      const foundParent = findParent(target, item.children, newParents);

      if (foundParent) {
        return foundParent;
      }
    }
  }
  return null;
}
function mapAndAddProperty(targetCode, targetRegion, data, isOpen) {
  return data.map(item => {
    // Check if the current item matches the search
    const isTarget =
      item?.codice_cliente?.toUpperCase() === targetCode?.toUpperCase() ||
      item?.ragione_sociale?.toLowerCase()?.trim().includes(targetRegion?.toLowerCase()?.trim());

    // Update the item to be open if it's the target or part of the target's path
    const newItem = {
      ...item,
      isOpen: isTarget ? isOpen : item.isOpen, // Open only the target or those in the path
      children: item.children
        ? mapAndAddProperty(targetCode, targetRegion, item.children, isOpen)
        : item.children,
    };

    // If the item has children and any child is open, this item should be open as well
    if (item.children && item.children.some(child => child.isOpen)) {
      newItem.isOpen = true;
    }

    return newItem;
  });
}

const toggleIsOpen = data => {
  return data?.map(item => ({
    ...item,
    isOpen: false,
    children: item.children ? toggleIsOpen(item.children) : item.children,
  }));
};
// Helper function to find the parent hierarchy of the target
function findTargetHierarchy(target, data) {
  for (const item of data) {
    if (
      item.codice_cliente?.trim().toLowerCase() === target.toLowerCase() ||
      item.ragione_sociale?.toLowerCase()?.trim().includes(target?.toLowerCase()?.trim())
    ) {
      return [item]; // Target found, return itself in the hierarchy
    }
    if (item.children) {
      const foundHierarchy = findTargetHierarchy(target, item.children);
      if (foundHierarchy) {
        return [item, ...foundHierarchy]; // Add this item as part of the hierarchy
      }
    }
  }
  return null;
}

// Helper function to collapse all items
function collapseAllItems(data) {
  return data.map(item => ({
    ...item,
    isOpen: false,
    children: item.children ? collapseAllItems(item.children) : item.children,
  }));
}

// Helper function to open target and its parent hierarchy
function openTargetAndParents(data, targetHierarchy) {
  return data.map(item => {
    const isInHierarchy = targetHierarchy.some(
      hierarchyItem => hierarchyItem.codice_cliente === item.codice_cliente,
    );

    const newItem = {
      ...item,
      isOpen: isInHierarchy ? true : item.isOpen, // Open only the target and its ancestors
      children: item.children
        ? openTargetAndParents(item.children, targetHierarchy)
        : item.children,
    };

    return newItem;
  });
}

// Search handler
function updateIsOpen(hierarchy, itemsToUpdate) {
  function updateItem(item, itemsToUpdate) {
    // Check if the current item's id matches any item in the itemsToUpdate array
    const itemToUpdate = itemsToUpdate.find(
      i => i.codice_cliente === item.codice_cliente && i.label === item.label,
    );

    if (itemToUpdate) {
      item.isOpen = true; // Update the item’s isOpen property
    }

    // If the item has children, recursively check them
    if (item.children) {
      item.children.forEach(child => updateItem(child, itemsToUpdate));
    }
  }

  hierarchy.forEach(item => updateItem(item, itemsToUpdate)); // Start checking from the root-level items
}

function Hierarchy() {
  const { singola_pattuizione_gerarchia } = useSelector(state => state?.user?.user || {});
  const individual_agreement = useSelector(state => state.app.config.individual_agreement);

  const [item, setItem] = useState();
  const [practicesList, setPraticesList] = useState();
  const [searchItem, setSearchItem] = useState('');
  const [data, setData] = useState([]);
  const [itemSelected, setItemSelected] = useState();
  useEffect(() => {
    const data = Array.isArray(singola_pattuizione_gerarchia)
      ? singola_pattuizione_gerarchia
      : [singola_pattuizione_gerarchia];
    setData(data);
  }, [singola_pattuizione_gerarchia]);
  console.log({ dataSearch: data });

  const searchHandler = e => {
    e.preventDefault();

    if (searchItem) {
      const collapsedData = toggleIsOpen(data);
      const parentItem = findParent(searchItem, collapsedData);
      console.log({ parentItem, collapsedData });
      if (parentItem?.length) {
        updateIsOpen(collapsedData, parentItem);

        console.log({ collapsedData });
        setData([...collapsedData]); // Update the state with the newly opened items
        setItem(null);
      } else {
        console.warn('No match found for search:', searchItem);
      }
    } else {
      setData(toggleIsOpen(data)); // Collapse all if no search term
    }
  };

  if (!individual_agreement) {
    return null;
  }

  return (
    <AccordionContext.Provider
      value={{
        item,
        setItem,
        data,
        setData,
        practicesList,
        setPraticesList,
        searchItem,
        setItemSelected,
        itemSelected,
      }}
    >
      <Wrapper>
        <Search align='end' gap='20px'>
          <Text bold size={30} primary className='label'>
            Gerarchia
          </Text>
          <form onSubmit={searchHandler} className='form'>
            <Input value={searchItem} onChange={e => setSearchItem(e.target.value)} />
            <Button active type='submit'>
              Cerca
            </Button>
          </form>
        </Search>
        <div style={{ overflowX: 'auto' }}>
          <GlobalHeader>
            <Text className='name' bold color='#fff' size={15}>
              Nome
            </Text>
            <Text className='code' bold color='#fff' size={15}>
              Codice cliente
            </Text>
            <Text className='ragine_social' bold color='#fff' size={15}>
              Ragione sociale
            </Text>
            <Text className='registered' bold color='#fff' size={15}>
              Registrato
            </Text>
            <Text className='accepte' bold color='#fff' size={15}>
              Singola pattuizione accettatat
            </Text>
            <Text className='details' bold color='#fff' size={15}></Text>
          </GlobalHeader>
          {data?.map((item, index) => (
            <AccordionItem key={index} item={item} parentIndex={0} searchHandler={searchHandler} />
          ))}
        </div>
        {itemSelected && <Actions />}
        {/*      {data.length > 0 && (
          <Button
            className='mt-20'
            active
            onClick={() => exportToExcelTable(data, 'singola_pattuizione_gerarchia')}
          >
            Export
          </Button>
        )} */}
        <ExportToExcel hierarchicalData={data} />
      </Wrapper>
    </AccordionContext.Provider>
  );
}
const Container = styled.div`
  margin-top: 40px;
  .details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    &-item {
      flex-grow: 1;
      width: 48%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .details-item.locality {
      width: 66%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .points {
    &-item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      padding: 10px 0;
    }
  }
`;

const AccordionHeader = styled.div`
  cursor: ${({ hasSubitems }) => (hasSubitems ? 'pointer' : 'default')};
  padding: 10px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  gap: 7px;
  min-width: calc(800px - ${({ parentIndex }) => parentIndex * 10}px);

  svg {
    color: ${({ theme }) => theme.primary};
  }

  .details-pratiche {
    font-size: 9px;
    display: flex;
    gap: 10px;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
    }
  }

  .code {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(20% - ${({ parentIndex }) => parentIndex * 5}px);
  }

  .name {
    width: calc(24% - ${({ parentIndex }) => parentIndex * 5}px);
  }
  .ragine_social {
    width: calc(21% - ${({ parentIndex }) => parentIndex * 5}px);
  }
  .registered {
    width: calc(12% - ${({ parentIndex }) => parentIndex * 5}px);
  }
  .accept {
    width: calc(19% - ${({ parentIndex }) => parentIndex * 5}px);
  }
  .details {
    /*  width: 20%;
    width: calc(20% + ${({ parentIndex }) => parentIndex * 5}px);
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center; */
    width: 6%;
    margin-left: auto;
  }

  &.open {
    background-color: #e0e0e0;
  }
`;

const Search = styled(Flex)`
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
  ${respondTo.md`
    justify-content: space-between;
  `}
  .form {
    background-color: #f4f6f9;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    button {
      border-radius: inherit;
    }
  }
  .form {
    flex-grow: 1;
    width: 100%;
    ${respondTo.md`
    flex-grow: initial;
    width: 35%
  `}
  }
`;
const NestedList = styled.div`
  padding-left: 5px;
  ${respondTo.sm`
    padding-left: 10px;
  `}
`;

const GlobalHeader = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  min-width: 800px;
  gap: 5px;
  .name {
    width: 25%;
  }
  .code {
    width: 20%;
  }
  .ragine_social {
    width: 20%;
  }
  .registered {
    width: 11%;
  }
  .accepte {
    width: 19%;
  }
  .details {
    width: 6%;
  }
`;

const AccordionItem = ({ item, parentIndex, searchHandler }) => {
  const [isOpen, setIsOpen] = useState(item.isOpen);

  const {
    item: selectedItem,
    setItem,
    data,
    setData,
    setPraticesList,
    searchItem,
    itemDetails,
    setItemSelected,
    itemSelected,
  } = useContext(AccordionContext);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setIsOpen(item.isOpen);

    //  setItem({ ...item, isOpen: item?.codice_cliente === selectedItem?.codice_cliente });
  }, [item.isOpen, data]);

  const checkChildMatches = children => {
    return children.some(child => {
      if (child.codice_cliente === itemSelected?.codice_cliente) {
        return true;
      }
      if (child?.children && child.children.length > 0) {
        return checkChildMatches(child.children);
      }
      return false;
    });
  };
  const checkIsDetails = row => {
    if (
      row?.children &&
      itemSelected &&
      row?.codice_cliente !== selectedItem?.codice_cliente &&
      checkChildMatches(row?.children) &&
      itemSelected?.isOpen
    ) {
      setItemSelected(null);
    }
  };
  console.log({ item });

  return (
    <>
      <AccordionHeader
        className={
          isOpen || item?.codice_cliente === selectedItem?.codice_cliente ? 'open' : 'closed'
        }
        onClick={() => {
          if (item?.children && item?.children.length > 0) {
            toggleAccordion();
            checkIsDetails(item);
          }
        }}
        parentIndex={parentIndex}
        hasSubitems={item.children}
      >
        <span className='name'>
          {item.children && <span>{isOpen ? <TiMinus /> : <TiPlus />}</span>} {item.label}
        </span>
        <span className='code'>{item.codice_cliente}</span>
        <span className='ragine_social'>{item.ragione_sociale}</span>
        <span className='registered'>
          {item?.details?.registered === true ? (
            <BiUserCheck color='green' />
          ) : item?.details?.registered === false ? (
            <BiUserX color='red' />
          ) : (
            ''
          )}
        </span>
        <span className='accept'>
          {item?.details?.individual_agreement_accepted === true ? (
            <MdOutlineCheckCircle color='green' />
          ) : item?.details?.individual_agreement_accepted === false ? (
            <FiXCircle color='red' />
          ) : (
            ''
          )}
        </span>
        <div className='details '>
          {!item.children && (
            <div className='details-pratiche'>
              <div
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                  setItem({
                    ...item,
                    isOpen: item?.codice_cliente === selectedItem?.codice_cliente,
                  });
                  setItemSelected({
                    ...item,
                    isOpen: true,
                  });
                }}
                style={{
                  color:
                    item?.codice_cliente === selectedItem?.codice_cliente && itemSelected && 'red',
                }}
              >
                <span>DETTAGLIO</span>
                <AiOutlineQuestionCircle
                  size={18}
                  color={
                    item?.codice_cliente === selectedItem?.codice_cliente && itemSelected && 'red'
                  }
                />
              </div>
            </div>
          )}
        </div>
      </AccordionHeader>
      {isOpen && item.children && (
        <NestedList>
          {item.children.map((item, index) => (
            <AccordionItem key={index} item={item} parentIndex={parentIndex + 1} />
          ))}
        </NestedList>
      )}
    </>
  );
};
const Actions = () => {
  const { item } = useContext(AccordionContext);

  return (
    <Wrapper>
      <Container>{item && <Details />}</Container>
    </Wrapper>
  );
};

const Details = () => {
  const { item } = useContext(AccordionContext);

  return (
    <Wrapper>
      {item.details.sections.map(elem => (
        <div>
          <Text size={20} bold style={{ marginBottom: '10px', marginTop: '30px' }}>
            {elem.label}
          </Text>
          <div className='points'>
            {elem.rows.map(row => (
              <div className='points-item'>
                <Text size={13}>{row.label}</Text>
                <Text size={13}>{row.value}</Text>
              </div>
            ))}
          </div>
        </div>
      ))}
    </Wrapper>
  );
};

export default Hierarchy;
