import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Col, Flex, Modal, Text } from '../Ui';
import { ReactComponent as PointIcon } from '../../assets/images/pointIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import ReactShadowScroll from 'react-shadow-scroll';
import moment from 'moment';
import { numberWithDots } from '../../utils/utils';
import { respondTo } from '../../theme/mixin';
import { getUserInfo } from '../../store/actions';
import receiptPlaceholder from '../../assets/images/receiptPlaceholder.png';
import { maxHeight, overflow, textAlign } from 'styled-system';
const CustomBox = styled(Box)`
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.colore_bordo_box};
  }
`;
const CustomCol = styled(Col)`
  svg {
    color: ${({ theme }) => theme.primary};
  }
`;
const CustomFlexHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colore_bordo_box};
  display: none;
  ${respondTo.sm`
  display: flex;
     `};
`;
const CustomColPoint = styled(Col)`
  width: 25%;
  ${respondTo.sm`
  width: 20%;
     `};
`;
const CustomText = styled(Text)`
  display: block;
  ${respondTo.sm`
  display: none;
     `};
`;
const Details = styled.details`
  padding: 15px;

  summary {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-weight: bold;

    margin-bottom: 5px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-size: 18px;
  text-align: left;
`;

const TableHead = styled.thead`
  background-color: #f2f2f2;
  position: sticky;
  top: -2px;
`;

const TableHeader = styled.th`
  padding: 12px 10px;
  border: 1px solid ${({ theme }) => theme.colore_bordo_box};
  span {
    display: none;
    ${respondTo.sm`
       display: block;
    `}
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colore_bordo_box};
`;

const TableData = styled.td`
  padding: 12px 10px;
  border: 1px solid ${({ theme }) => theme.colore_bordo_box};
`;
const Section = styled.div`
  .modal-receipt {
    max-height: 500px;
    ${respondTo.sm`
       max-height: 80vh;
    `}
  }
`;

const Punti = ({ text, num }) => {
  const { actionsPerCategory } = useSelector(state => state.user.user || {});
  const pointsLabel = useSelector(state => state.app.config.points_label);

  return text === 'Punti maturati' ? (
    <Details val={num}>
      <summary>
        <Text size={20} text_box>
          {pointsLabel} maturati
        </Text>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </summary>

      {actionsPerCategory &&
        Object.keys(actionsPerCategory)?.map(item => (
          <Flex justify='space-between' align='center' wrap='nowrap' className='mb-10'>
            <Text size={20} text_box>
              {item}
            </Text>
            <Text size={20} text_box>
              {actionsPerCategory[item].total}
            </Text>
          </Flex>
        ))}
    </Details>
  ) : (
    <Flex justify='space-between' align='center' wrap='nowrap'>
      <Col width={50}>
        <Text size={20} text_box>
          {text}
        </Text>
      </Col>
      <Col width={50}>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </Col>
    </Flex>
  );
};
const Coin = ({ text, num, category, date }) => (
  <>
    <Flex align='center' wrap='nowrap'>
      <Col width={80}>
        <Flex gap={30}>
          <Col width={50} padding={5}>
            <Flex wrap='nowrap' gap={5}>
              <CustomText bold capit text_box>
                Data:
              </CustomText>
              <Text size={18} text_box style={{ whiteSpace: 'nowrap' }}>
                {moment(date).format('DD/MM/YYYY HH:MM')}
              </Text>
            </Flex>
          </Col>
          <Col width={50} padding={5}>
            <Text size={18} text_box>
              {category}
            </Text>
          </Col>
        </Flex>
      </Col>
      <CustomColPoint width={20}>
        <Text size={18} upper type='text' text_box align='right'>
          {numberWithDots(num)}
        </Text>
      </CustomColPoint>
    </Flex>
  </>
);

const WelfareCodes = ({ data }) => {
  return (
    <ReactShadowScroll
      style={{ width: '100%', maxHeight: '350px' }}
      isShadow={false}
      scrollColor={'#cccccc'}
      scrollColorHover='gray'
      styleSubcontainer={{ overflowX: 'hidden' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Codice</TableHeader>
            <TableHeader>Data scadenza</TableHeader>
            <TableHeader>Importo</TableHeader>
            <TableHeader>Utilizzato</TableHeader>
            <TableHeader>Disponibile</TableHeader>
          </TableRow>
        </TableHead>

        <tbody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableData>{item.code}</TableData>
              <TableData>{item.expiration_date}</TableData>
              <TableData>{item.total_amount}</TableData>
              <TableData>{item.used_amount}</TableData>
              <TableData>{item.available_amount}</TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ReactShadowScroll>
  );
};

const Ocr = ({ data, setValidMsg, setReceitImg }) => {
  const newData = data.sort((a, b) => {
    const dateA = a.date ? new Date(a.date) : new Date('1970-12-31');
    const dateB = b.date ? new Date(b.date) : new Date('1970-12-31');
    return dateB - dateA;
  });

  return (
    <>
      <Text size={22} className='mt-20 mb-20 ' style={{ paddingLeft: '5px' }}>
        Le tue ricevute
      </Text>
      <ReactShadowScroll
        style={{ width: '100%', maxHeight: '350px' }}
        isShadow={false}
        scrollColor={'#cccccc'}
        scrollColorHover='gray'
        styleSubcontainer={{ overflowX: 'hidden' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Immagine</TableHeader>
              <TableHeader>Data</TableHeader>
              <TableHeader>Punti</TableHeader>
              <TableHeader>Prodotto</TableHeader>
              <TableHeader>
                <span>Esito</span>
              </TableHeader>
            </TableRow>
          </TableHead>
          <tbody>
            {newData.map((item, index) => (
              <TableRow key={index}>
                <TableData style={{ textAlign: 'center' }}>
                  <img
                    src={receiptPlaceholder}
                    width={50}
                    onClick={() => setReceitImg(item.file_url)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableData>
                <TableData>{item.date}</TableData>
                <TableData>{item.points}</TableData>
                <TableData>{item.product}</TableData>
                <TableData>
                  {item.valid ? (
                    <Text bold color='#008000' size={20}>
                      V
                    </Text>
                  ) : (
                    <Text
                      bold
                      color='#E22631'
                      size={20}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setValidMsg(item.message);
                      }}
                    >
                      X
                    </Text>
                  )}
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </ReactShadowScroll>
    </>
  );
};

const Points = () => {
  const {
    userinfo: { score, score_spent, score_gained, score_pending } = {},
    welfare_codes,
    actions,
    base_threshold_enabled,
    base_threshold_description,
    base_threshold_percent,
    targets,
    actionsPerCategory,
    ocr_datas,
  } = useSelector(state => state.user.user || {});
  const [validMsg, setValidMsg] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  const appName = useSelector(state => state.app.config.code);
  const pointsLabel = useSelector(state => state.app.config.points_label);
  const pointsNote = useSelector(state => state.app.config.points_note);
  const [receiptImg, setReceiptImg] = useState(null);

  return (
    <Section>
      {base_threshold_enabled && (
        <Box>
          <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
            Soglia base {base_threshold_description}
          </Text>
          <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
            Hai raggiunto il
          </Text>
          <Text align='center' as='h3' bold type='title' primary className='mb-10' text_box>
            {base_threshold_percent}
          </Text>
          <Text align='center' as='h3' bold type='mediumTitle' text_box>
            della soglia base
          </Text>
        </Box>
      )}
      {targets?.length > 0 && (
        <Box style={{ marginTop: '15px' }}>
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '180px' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {targets?.map(item => (
              <Text primary capit className='mb-10' size={20} style={{ paddingLeft: '15px' }}>
                Obiettivo {item.month}: {item.target}
              </Text>
            ))}
          </ReactShadowScroll>
        </Box>
      )}
      {welfare_codes?.length > 0 && <WelfareCodes data={welfare_codes} />}
      <CustomBox margin='15px 0 0 0'>
        <Punti
          text={`${pointsLabel} maturati`}
          num={score_gained}
          actionsPerCategory={actionsPerCategory}
        />
        <Punti text={`${pointsLabel} spesi`} num={score_spent} />
        <Punti text={`${pointsLabel} disponibili`} num={score} />

        {appName && appName === 'WURTH' ? (
          <Punti text={`${pointsLabel} da confermare`} num={score_pending} />
        ) : null}
      </CustomBox>
      {pointsNote && <Text className='mt-20'>{pointsNote}</Text>}
      {actions?.length > 0 && (
        <Box style={{ height: '100%' }} margin='15px 0 0 0'>
          <CustomFlexHeader align='center' wrap='nowrap'>
            <Col width={80} padding={0}>
              <Flex>
                <Col width={50}>
                  <Text size={18} bold primary align='left' style={{ paddingLeft: '8px' }}>
                    Data
                  </Text>
                </Col>
                <Col width={50} style={{ paddingLeft: '0' }}>
                  <Text size={18} bold primary align='left'>
                    Categorie
                  </Text>
                </Col>
              </Flex>
            </Col>
            <Col width={20} padding={0}>
              <Text size={18} capit bold primary align='right' style={{ paddingRight: '20px' }}>
                {pointsLabel}
              </Text>
            </Col>
          </CustomFlexHeader>
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '370px' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {actions?.map(({ id, details, points, category, created_at }) => (
              <Coin key={id} num={points} text={details} category={details} date={created_at} />
            ))}
          </ReactShadowScroll>
        </Box>
      )}
      {ocr_datas?.length > 0 && (
        <Ocr data={ocr_datas} setValidMsg={setValidMsg} setReceitImg={setReceiptImg} />
      )}
      <Modal
        show={Boolean(validMsg)}
        close={() => {
          setValidMsg('');
        }}
      >
        <Text className='mb-20'>{validMsg}</Text>
      </Modal>
      <Modal
        show={Boolean(receiptImg)}
        style={{ overflow: 'scroll', WebkitOverflowScrolling: 'touch' }}
        classNameContent='modal-receipt'
        close={() => {
          setReceiptImg('');
        }}
      >
        <img src={receiptImg} alt='' /* style={{ height: '100%', minHeight: '200px' }} */ />
      </Modal>
    </Section>
  );
};
export default Points;
