import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTransition, useChain, animated } from 'react-spring';
import { HighlightOff } from '@styled-icons/material-twotone';
import { respondTo } from '../../../theme/mixin';

import { Button, Text } from '../';

const Overlay = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  z-index: 999;
  overflow: auto;
`;

const Content = styled(animated.div)`
  width: 90%;
  max-width: 950px;
  background-color: ${props =>
    props.theme.modal
      ? props.theme.modal
      : props.alert
      ? props.theme.bg_alert
      : props.theme.bg_box};
  border-radius: ${({ alert, theme }) => (alert ? theme.border_radius_generale_piccolo : '10px')};
  position: absolute;
  padding: ${({ isFixed }) => (isFixed ? '50px 0 20px 7px' : '40px 15px 20px 15px')};
  top: 80px;
  z-index: 998;
  border: 2px solid ${props => (props.alert ? props.theme.colore_bordo_alert : 'transparent')};
  color: ${props => (props.alert ? props.theme.colore_testo_alert : props.theme.colore_testo_box)};
  box-shadow: 0px 5px 15px #00000026;
  *:not(button) {
    ${props => props.alert && 'color: ' + props.theme.colore_testo_alert};
  }

  ${respondTo.sm`
    top: 50px;
  `}
`;

const ModalContainer = styled.div`
  position: relative;
`;

const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;

const Modal = props => {
  const { close, children, show, type, isFixed, alert, hideClode, style, classNameContent } = props;

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => (document.body.style.overflow = 'auto');
  }, [show]);

  const overlayTransitionRef = useRef();
  const overlayTransition = useTransition(show, null, {
    ref: overlayTransitionRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const modalTransitionRef = useRef();
  const modalTransition = useTransition(show, null, {
    ref: modalTransitionRef,
    unique: true,
    trail: 100,
    from: { opacity: 0, transform: 'translateY(-40px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' },
  });

  useChain(
    show ? [overlayTransitionRef, modalTransitionRef] : [modalTransitionRef, overlayTransitionRef],
    [0, show ? 0.1 : 0.6],
  );

  return overlayTransition.map(({ item, key, props }) =>
    item ? (
      <Overlay key={key} style={props}>
        {modalTransition.map(({ item, key, props }) =>
          item ? (
            <Content
              key={key}
              style={{ ...props, ...style }}
              type={type}
              isFixed={isFixed}
              alert={alert}
              className={classNameContent}
            >
              {!hideClode && (
                <CloseModal onClick={close}>
                  <HighlightOff size={30} />
                </CloseModal>
              )}
              <ModalContainer>
                {children}
                {type === 'error' && (
                  <Button secondary onClick={close} style={{ marginTop: 20, width: 200 }}>
                    Ok
                  </Button>
                )}
              </ModalContainer>
            </Content>
          ) : null,
        )}
      </Overlay>
    ) : null,
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  show: PropTypes.bool,
};

export default Modal;
